/*
 * @version: 1.0
 * @Date: 2021-10-13 09:21:42
 * @LastEditTime: 2023-10-12 13:34:06
 * @FilePath: \otc-coupon\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout/index.vue";
import blankPage from "../views/Layout/blankPage.vue";

const originalReplace = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

// Vue.use(VueRouter);
export const constantRoutes = [
  {
    path: "/",
    hidden: true,
    redirect: "/login",
    component: blankPage,
    children: [
      {
        name: "Login",
        path: "/login",
        component: () => import("../views/login.vue"),
        hidden: true,
        meta: {
          title: "登录"
        }
      }
    ]
  },
  // // 微信小程序隐私协议
  // {
  //   path: "/privacyAgreement",
  //   hidden: true,
  //   redirect: "/privacyAgreementDetail",
  //   component: blankPage,
  //   children: [
  //     {
  //       name: "PrivacyAgreementDetail",
  //       path: "/privacyAgreementDetail",
  //       component: () => import("../views/agreement_wx/privacyAgreement"),
  //       hidden: true,
  //       meta: {
  //         title: "隐私协议"
  //       }
  //     }
  //   ]
  // }
];

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();

  router.matcher = newRouter.matcher;
}

export default router;
