const Trim = function (Vue) {
  function dispatchEvent(el, type) {
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent(type, true, true);
    el.dispatchEvent(evt);
  }

  let handelChang = function (element) {
    let matchAllReg = /(\s+)/g;
    let matchAllRes = [...this.value.matchAll(matchAllReg)];

    // 解决用户前后中间都有空格只处理第一个空格的问题
    if (matchAllRes.length > 1) {
      Promise.resolve().then(() => {
        this.value = this.value.replace(/\s*/g, "");
        dispatchEvent(element, "input");
      });
      return;
    }

    let reg = /(\s+)/;

    let res = this.value.match(reg);

    if (res && res.index == 0) {
      Promise.resolve().then(() => {
        this.value = this.value.substring(1, this.value.length);
        dispatchEvent(element, "input");
      });
    } else if (res && res.index == this.value.length - 1) {
      Promise.resolve().then(() => {
        this.value = this.value.substring(0, this.value.length - 1);
        dispatchEvent(element, "input");
      });
    } else if (res) {
      Promise.resolve().then(() => {
        this.value = this.value.replace(/\s*/g, "");
        dispatchEvent(element, "input");
      });
    }
  };

  Vue.directive("Trim", {
    inserted: function (el) {
      let element = el.querySelector(".el-input__inner");

      element.addEventListener("input", function () {
        handelChang.call(element, element);
      });
    },
    unbind: function (el) {
      let element = el.querySelector(".el-input__inner");
      element.removeEventListener("input", handelChang);
    }
  });
};

export default Trim;
